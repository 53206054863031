<template>
  <v-card 
    :flat="!smUp"
    rounded
    :style="smUp ? 'padding: 15px; margin-left: -5%':''">
    <v-data-table
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="20"
      item-key="_id"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length"> 
          <v-layout
            style="margin: 10%"
            column>
            <p
              v-text="'Description: '"
              style="font-family: 'Open sans', sans-serif; font-size: 10px; color: #000; margin-bottom: 10px"
            />
            <p
              v-text="item.sMessage"
              style="font-family: 'Open sans', sans-serif; font-size: 15px; color: #000"
            />
          </v-layout>
<!--           <v-layout
            row
            justify-center
            style="margin: 5%;">
            <v-btn
              fab
              small
              icon
              text            
              @click="delete_notification(item)">
              <v-icon
                color="red">
                {{delete_icon}}
              </v-icon>
            </v-btn>
          </v-layout> -->
        </td>
      </template>
    </v-data-table>  
<!--     <notification-delete
      @update="update()"
      @close="bDeleteNotificacion = false"
      :bActive="bDeleteNotificacion"
      :notification_id="notification_id"
    /> -->
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      delete_icon: 'mdi-delete',
      bDeleteNotificacion: false,
      notification_id: '',
      expanded: [true],
      singleExpand: true,
      headers: [
        { text: 'Title', value: 'sTitle' },
        { text: 'Date', value: 'formatedCreatedAt' },
        // { text: 'Descripción', value: 'sDescription' }
      ]
    }),
    methods: {
      delete_notification (notification) {
        this.notification_id = notification._id
        this.bDeleteNotificacion = true
      },
      update () {
        this.$emit('update')
      },
      truncate_notification (notification) {
        try { 
          var string = notification.substring(0, 20)
          return string
        }
        catch {
          return notification
        }
      }
    },
    components: {
      // NotificationsDelete: () => import('@/components/sections/Admin/Notifications/NotificationsDelete')
    },
    props: {
      items: Array
    },
    computed: {
      smUp () {
        return this.$vuetify.breakpoint.smAndUp
      }
    }
  }
</script>
<style scoped>
  .attribute_flex {
    margin-top: 18px;
  }
  hr { 
    height: .5px;
    color: rgba(140, 140, 140, 0.112);
    background-color: rgba(114, 114, 114, 0.112);
    border-color: transparent;
  }
  .attribute_name {
    font-weight: bold;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .attribute_value {
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .application_table_row {
    cursor: pointer;
  }
  .text_content {
    margin-top: 15px;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
</style>